import React from "react"
import Layout from "../components/layout/layout.js"
import { css } from "@emotion/react"
import {graphql} from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import BlogThumbnail from './../components/blog-thumbnail/blog-thumbnail'
import RecentPosts from "./../components/recent-posts/RecentPosts";
import EmailForm from "../components/email-form/EmailForm.js";
import {blogTitle,padBottom} from "./index.module.css"

const IndexPage = () => (
	<Layout>
		<Seo title="Home" 
			keywords={[`Ara Intelligence`, `robotics`, `computer vision`,'Data Engineering']} />
		<div className={`row ${padBottom}`}>
			<div className="col">
				<h3 className={blogTitle}>
					A publication about Computer Vision and Deep Learning.
				</h3>
			</div>
		</div>

		<div className="">
			<h2 className="text-left">Explore recent posts</h2>
		</div>
		<div className="grid md:grid-rows-1" css={css`margin-bottom: 1rem;`}>
			<RecentPosts count="6"/>
		</div>
		
		<div className="grid md:grid-rows-1" css={css`background-color: rgba(236, 240, 241, 1);padding: 10px;`}>
			<h2 className="text-left">Browse the categories</h2>
			<div className="grid md:grid-cols-2 gap-4" css={css`margin: 2rem; padding: 1rem;`}>
				<div className="card bordered md:max-w-sm">
                    <BlogThumbnail 
                        title="Computer Vision"
                        blog_url="/blogs/computer-vision"
                        cover_art="geordanna-cordero-fields-762610-unsplash.jpg">
                            <StaticImage 
                                src="./../images/geordanna-cordero-fields-762610-unsplash.jpg" 
                                alt="Thumbnail image for computer vision publication"
                                objectFit="cover"
                                className="thumbnail-image" 
                                placeholder="blurred" />
                    </BlogThumbnail>
                </div>
                <div className="card bordered md:max-w-sm">
                    <BlogThumbnail 
                        title="Deep Learning"
                        blog_url="/blogs/deep-learning">
                            <StaticImage 
                                src="https://cdn.araintelligence.com/images/cover-art/annie-spratt-695511-unsplash.jpg"
                                alt="Thumbnail image for deep learning publication"
                                objectFit="cover"
                                className="thumbnail-image"
                                placeholder="blurred" />
                    </BlogThumbnail>
                </div>
			</div>
		</div>

        {/* Signup for email newsletters */}
        <div className="row justify-content-center">
            <EmailForm />
        </div>
	</Layout>
)

export default IndexPage

export const query = graphql`
	query {
		file(relativePath: { eq: "undraw_newsletter_vovu.svg" }) {
			publicURL
		}
	}
`