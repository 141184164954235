import React, {useState} from "react";
import {formDescription, revueFormFooter} from "./SignupForm.module.css";

export function SignupForm(props) {
    const [loading, setLoading] = useState(false);
    
    function submitForm (ev) {
        ev.preventDefault();

        setLoading(true);
        setTimeout(() => {
            const signupForm = document.getElementById("revue-form");
            signupForm.submit();

            if (props.willSubmitForm) {
                props.willSubmitForm();
            }
        }, 900);
    }

    return (
        <div className="">
            <div className={`col space-y-3`}>
                <h1>Sign up to my newsletter</h1>
                <p className={formDescription}>I'd notify you when I publish a new article and also write about recent developments in computer vision and deep learning I've come across.</p>
                <div id="revue-embed place-content-evenly gap-2">
                    <form 
                        action="https://www.getrevue.co/profile/fullmetal_engineer/add_subscriber" method="post" 
                        id="revue-form" className="space-y-6"
                        name="revue-form" target="_blank" >
                        <div className="revue-form-group form-control">
                            {/* <label htmlFor="member_email label">Email address</label> */}
                            <input className="revue-form-field input input-bordered" placeholder="Your email address..." type="email" name="member[email]" id="member_email" required />
                        </div>
                        <div className="revue-form-actions">
                            <button type="submit" onClick={submitForm} name="member[subscribe]"
                                id="member_submit" className="btn btn-dark">
                                    Subscribe &nbsp;
                                    {
                                        loading 
                                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        : ""
                                    }
                            </button>
                        </div>
                        <div className={revueFormFooter}>By subscribing, you agree with Revue’s <a target="_blank" href="https://www.getrevue.co/terms" rel="noreferrer">Terms of Service</a> and <a target="_blank" href="https://www.getrevue.co/privacy" rel="noreferrer">Privacy Policy</a>.</div>
                    </form>
                </div>
            </div>
        </div>
    );
}