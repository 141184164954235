import React from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import "./RecentPosts.css"

/**
 * This is a non page component in the sense that this component is 
 * expected to be embedded in other components.
 * card-img-top contain thumbnail-image
 * Only the 5 most recent posts are shown
 * 
 * @param props.count	The maximum number of recent posts to show
 * 
 */
export default function RecentPosts(props){
	const data = useStaticQuery(query);
	const numPosts = props.count ? props.count : 3;
	let posts = data.allMdx.edges;
	
	if (posts.length > numPosts){
		posts = posts.slice(0, numPosts);
	}

	return (
		<div className={`recent-posts grid md:grid-cols-3 gap-4`}>
			{posts.map( ({node},idx) => (
				<div className="card border border-gray-200" key={node.id}>
                    <Link to={node.fields.pathName}>
                        <figure>
                            <img src={node.frontmatter.poster_image} alt="" className="w-full" />
                        </figure>
                    </Link>
                    <div className="card-body">
                        <h5 className="card-title">
                            <Link to={node.fields.pathName}>
                                {node.frontmatter.title}
                            </Link>
                        </h5>
                        <p className="text-left card-text">{node.excerpt}</p>
                    </div>
                    <div className="card-actions card-footer">
                        <small className="text-left">{node.frontmatter.date}</small>
                        <small className="text-right mins-read">{node.timeToRead} mins read</small>
                    </div>
				</div>
			))}
		</div>
	)
}


const query = graphql`
	query RecentPosts{
		allMdx (sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    fileAbsolutePath
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
						archive
						editor
						poster_image 
                    }
                    excerpt(pruneLength: 250)
                    timeToRead
                    fields {
                        pathName
                    }
                }
            }
        }
	}
`;