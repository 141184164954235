import React, { Component } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import {SignupForm} from "./signup-form/SignupForm.js";
import './EmailForm.css';

class EmailForm extends Component {
	constructor(props){
		super(props);
		this.state = { status: 'signup' };
		this.handleSubmit = this.handleSubmit.bind(this);
	}

    componentDidMount () {}

	handleSubmit = () => {
        this.setState({ status: 'loading' });
        setTimeout(() => {
            this.setState({ status: 'done' });
        }, 1000); // wait 1s for loading animation
	}

	render() {
		if( this.state.status === "loading" ){
			return (<div className="loader"></div>);
		}else if( this.state.status === "done" ){
			return (
                <div className="signup-form">
                    <div className="grid md:grid-rows-1">
                        <div className="col-md-12">
                            <h1>Thanks for subscribing</h1>
                            <StaticImage 
                                src="./../../images/crayon-2237.png" 
                                alt="illustration for email newsletter signup success"
                                objectFit="cover"
                                className=""
                                width={650}
                                placeholder="blurred" />
                        </div>
                        <div>
                            <small className="attribution">
                                Illustration by <a href="https://icons8.com/illustrations/author/5e7e24ce01d0360013bb7479">Natasha Remarchuk</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
                                {/* https://icons8.com/illustrations/illustration/crayon-2237 */}
                            </small>
                        </div>
                    </div>
                </div>
            )
		}else{
			return (
				<div className="signup-form grid md:grid-rows-1">
                    <div className="grid md:grid-cols-2">
                        <div className="col-md-6">
                            <StaticImage 
                                src="./../../images/transistor-delivery.png" 
                                alt="illustration for email newsletter signup"
                                objectFit="cover"
                                className="" 
                                placeholder="blurred" />
                            <small className="attribution">
                                Illustration by <a href="https://icons8.com/illustrations/author/5c18c58a793948000f7394ce">Marina Fedoseenko</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
                                {/* https://icons8.com/illustrations/illustration/transistor-delivery */}
                            </small>
                        </div>
                        <div className="col-md-6 self-center">
                            <SignupForm willSubmitForm={this.handleSubmit} />
                        </div>
                    </div>
					
				</div>
			);
		}
	}
}

export default EmailForm;